import React from 'react'
import * as yup from 'yup'
import {
  Box,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import Logo from '@assets/logo.png'
import Image from 'next/image'
import FlatTextField from '@components/FlatTextField'
import { Language as LanguageIcon } from '@mui/icons-material'
import NextLink from '@imports/NextLink'
import LanguageSelect from '@imports/LanguageSelect'
import { useTranslation } from 'next-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useFormik } from 'formik'
import { useVerifyDomain } from '@api/domain'
import FlatButton from '@components/FlatButton'
import { toast } from 'react-toastify'
import { useRouter } from 'next/router'
import SchoolBagSmMascot from '@assets/mascots/SchoolBagSmMascot'
import SchoolBagMascot from '@assets/mascots/SchoolBagMascot'

const validationSchema = yup.object().shape({
  domain: yup.string().subdomain().required('Field is required'),
})

export function LoginSection() {
  const { t, i18n } = useTranslation(['common', 'login'])
  const direction = i18n.dir(i18n.language)
  const router = useRouter()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const verifyDomain = useVerifyDomain()
  const formik = useFormik({
    initialValues: {
      domain: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (executeRecaptcha)
        executeRecaptcha('')
          .then((captchaToken) => {
            verifyDomain
              .mutateAsync({ domain: values.domain, 'g-recaptcha-response': captchaToken })
              .then((res) => {
                if (
                  res?.data?.data?.domain?.[0]?.includes('The institution has already been taken')
                ) {
                  window.location.href = `https://${values.domain}.blueassembly.com/admin/${router.locale}/login`
                } else {
                  formik.setFieldError('domain', 'Domain not found')
                }
              })
              .catch(() => {
                toast.error('Failed to login')
              })
              .finally(() => {
                actions.setSubmitting(false)
              })
          })
          .catch(() => {
            toast.error('Failed to generate token')
            actions.setSubmitting(false)
          })
      else {
        toast.error('Failed to generate token')
        actions.setSubmitting(false)
      }
    },
  })

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'absolute',
          display: { xs: 'none', md: 'block' },
          left: 0,
          transform: `scaleX(${i18n.dir(i18n.language) === 'rtl' ? -1 : 1})`,
          zIndex: -1,
        }}
      >
        <SchoolBagMascot />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          display: { xs: 'block', md: 'none' },
          left: 0,
          transform: `scaleX(${i18n.dir(i18n.language) === 'rtl' ? -1 : 1})`,
          zIndex: -1,
        }}
      >
        <SchoolBagSmMascot />
      </Box>
      <Grid
        container
        flexDirection="column"
        // spacing={4}
        sx={{ pt: 4, pb: 4, minHeight: '100vh' }}
      >
        <Grid item>
          <Container maxWidth="xl">
            <Grid
              container
              spacing={6}
              alignItems="flex-end"
            >
              <Grid
                item
                xs={12}
                md={5}
              >
                <Box
                  mt={{ xs: 5, md: '450px' }}
                  width={{ xs: 100, md: 305 }}
                >
                  <NextLink
                    href="/"
                    aria-label="home page"
                  >
                    <Image
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                      }}
                      alt={t('common.blue_assembly_logo')}
                      src={Logo}
                    />
                  </NextLink>
                </Box>
                <Typography
                  variant="caption"
                  display="block"
                  color="neutral.dark"
                  sx={{ fontSize: { xs: 10, md: 'inherit' } }}
                  textTransform="capitalize"
                >
                  {t('login:the_essential_school_management_system')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
              >
                <Paper sx={{ borderRadius: 4, overflow: 'hidden' }}>
                  <Box
                    py={5}
                    px={{ xs: 3, md: 8 }}
                    component="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Typography
                      variant="h4"
                      sx={{ fontSize: { xs: '25px !important', md: '40px !important' } }}
                      fontWeight={700}
                      gutterBottom
                      textTransform="capitalize"
                    >
                      {t('login:welcome_back')}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      color="neutral.dark"
                      sx={{ fontSize: { xs: 14, md: 'inherit' } }}
                    >
                      {t('login:Sign_in_to_your_account')}
                    </Typography>
                    <Box
                      mt={8}
                      mb={5}
                    >
                      <Grid
                        container
                        spacing={5}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <FlatTextField
                            name="domain"
                            value={formik.values.domain}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.domain && Boolean(formik.errors.domain)}
                            helperText={formik.touched.domain && formik.errors.domain}
                            fullWidth
                            label={t('common:Institution_name')}
                            backgroundColor="neutral.main"
                            size="large"
                            StartIcon={LanguageIcon}
                            inputProps={{
                              sx: { fontSize: { xs: 13, md: 'inherit' } },
                              'aria-label': t('common:Institution_name'),
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            InputProps={{
                              style: { direction: 'ltr' },
                              endAdornment: (
                                <InputAdornment position={direction === 'rtl' ? 'start' : 'end'}>
                                  <TextField
                                    size="large"
                                    select
                                    inputProps={{
                                      sx: { fontSize: { xs: 13, md: 'inherit' } },
                                      'aria-label': t('common:Domain'),
                                    }}
                                    sx={{
                                      width: { xs: 125, sm: 225, md: 200, lg: 250 },
                                      border: 1,
                                      ml: direction === 'rtl' ? -1.75 : 0,
                                      mr: direction === 'ltr' ? -1.75 : 0,
                                      borderTopLeftRadius: direction === 'rtl' ? 8 : 0,
                                      borderBottomLeftRadius: direction === 'rtl' ? 8 : 0,
                                      borderTopRightRadius: direction === 'ltr' ? 8 : 0,
                                      borderBottomRightRadius: direction === 'ltr' ? 8 : 0,
                                      borderColor: 'primary.main',
                                    }}
                                    defaultValue="blueassembly"
                                  >
                                    {[
                                      {
                                        value: 'blueassembly',
                                        label: '.blueassembly.com',
                                      },
                                    ].map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        style={{ direction: 'ltr' }}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <FlatButton
                      variant="contained"
                      disableElevation
                      sx={{ my: 2 }}
                      aria-label="login"
                      isLoading={formik.isSubmitting}
                      type="submit"
                    >
                      {t('common:login')}
                    </FlatButton>
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      color="neutral.dark"
                      sx={{ fontSize: { xs: 14, md: 'inherit' } }}
                      display="block"
                    >
                      <NextLink
                        href="/register"
                        aria-label="register page"
                      >
                        {t("login:Don't_have_an_account")}{' '}
                        <Typography
                          variant="body1"
                          component="span"
                          fontWeight={600}
                          color="#000"
                          fontSize="inherit"
                          textTransform="capitalize"
                        >
                          {t('login:register_now')}
                        </Typography>
                      </NextLink>
                    </Typography>
                  </Box>
                  <Box
                    sx={{ backgroundColor: 'primary.main' }}
                    p={1}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      color="white"
                      textAlign="center"
                      sx={{ fontSize: { xs: 14, md: 'inherit' } }}
                      display="block"
                    >
                      <NextLink
                        href="/parent-login"
                        aria-label="parent login page"
                      >
                        {t('login:Are_you_a_parent')}{' '}
                        <Typography
                          variant="body1"
                          component="span"
                          fontWeight={600}
                          sx={{ fontSize: { xs: 14, md: 'inherit' } }}
                          textTransform="capitalize"
                        >
                          {t('login:login_here')}
                        </Typography>
                      </NextLink>
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          item
          flexGrow={1}
          justifyContent="center"
          alignItems="flex-end"
          container
        >
          <Grid
            item
            sx={{ mt: 4 }}
          >
            <LanguageSelect />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoginSection
