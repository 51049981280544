import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useTranslation } from 'next-i18next'

function CaptchaProvider({ children }) {
  const { i18n } = useTranslation()
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
      language={i18n.language}
      // useRecaptchaNet="[optional_boolean_value]"
      // useEnterprise="[optional_boolean_value]"
      // scriptProps={{
      //   async: false, // optional, default to false,
      //   defer: false, // optional, default to false
      //   appendTo: 'head', // optional, default to "head", can be "head" or "body",
      //   nonce: undefined // optional, default undefined
      // }}
      // container={{
      //   // optional to render inside custom element
      //   element: '[required_id_or_htmlelement]',
      //   parameters: {
      //     badge: '[inline|bottomright|bottomleft]', // optional, default undefined
      //     theme: 'dark', // optional, default undefined
      //   },
      // }}
    >
      {children}
    </GoogleReCaptchaProvider>
  )
}

export default CaptchaProvider
