import React from 'react'
import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation, withTranslation } from 'next-i18next'
import { LoginSection } from '@sections/login'
import CaptchaProvider from '@contexts/CaptchaProvider'

function Login() {
  const { t } = useTranslation(['meta'])
  return (
    <div>
      <Head>
        <title>{`${t('meta:login.title')} - Blue Assembly`}</title>
        <meta
          name="description"
          content={t('meta:login.description')}
        />
      </Head>
      <CaptchaProvider>
        <LoginSection />
      </CaptchaProvider>
    </div>
  )
}

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale)),
  },
})

export default withTranslation(['common'])(Login)
