import { useMutation, useQuery } from '@tanstack/react-query'
import { httpClient } from './httpClient'

export const useVerifyDomain = () =>
  useMutation((payload) => httpClient.get('/auth/domain_validation', { params: payload }))

export const useMonitDomainStatus = (domain, enabled) =>
  useQuery(
    ['domain-status'],
    async () => {
      const response = await httpClient.get(`/tenant/status/${domain}`)
      return response.data.data
    },
    {
      refetchInterval: 5000,
      enabled,
    }
  )
